import { ApolloProvider } from '@apollo/client'
import { GoogleTagManager } from '@next/third-parties/google'
import { useApollo } from 'hooks/useApollo'
import type { AppProps } from 'next/app'
import { Open_Sans, Poppins } from 'next/font/google'
import localFont from 'next/font/local'
import Script from 'next/script'
import { appWithTranslation } from 'next-i18next'
import { BaseAppProps, NextPageWithLayout } from 'types/next'
import { Toaster } from 'components/ui/Toast'
import { AmplitudeProvider } from 'providers/AmplitudeProvider'
import { LayoutProvider } from 'providers/LayoutProvider'
import '../../globals.css'

/*
 Uncomment for SSG
const getStaticProps = makeStaticProps(['common'])
export { getStaticPaths, getStaticProps }
*/

type AppPropsWithLayout = AppProps<BaseAppProps> & {
  Component: NextPageWithLayout<BaseAppProps>
}

const App = ({ Component, pageProps }: AppPropsWithLayout) => {
  const apolloClient = useApollo(pageProps.initialApolloState)
  const getLayout = Component.getLayout ?? ((page) => page)

  return (
    <>
      <GoogleTagManager gtmId="GTM-T3463653" />
      <ApolloProvider client={apolloClient}>
        <AmplitudeProvider>
          <LayoutProvider>{getLayout(<Component {...pageProps} />)}</LayoutProvider>
        </AmplitudeProvider>
      </ApolloProvider>
    </>
  )
}

const AppWithI18n = appWithTranslation(App)

const openSans = Open_Sans({ subsets: ['latin'], variable: '--font-open-sans' })
const poppins = Poppins({
  subsets: ['latin'],
  variable: '--font-poppins',
  weight: ['400', '500', '600'],
})
const openSansCondensed = localFont({
  src: [
    {
      path: '../../public/fonts/OpenSansCondensed/OpenSansCondensed-Light.woff2',
      weight: '400',
      style: 'normal',
    },
    {
      path: '../../public/fonts/OpenSansCondensed/OpenSansCondensed-Bold.woff2',
      weight: '700',
      style: 'normal',
    },
  ],
  variable: '--font-open-sans-condensed',
})

const AppWithAuth = (props: AppPropsWithLayout) => (
  <>
    {
      // eslint-disable-next-line react/no-unknown-property
      <style jsx global>{`
        :root {
          --font-open-sans: ${openSans.style.fontFamily};
          --font-poppins: ${poppins.style.fontFamily};
          --font-open-sans-condensed: ${openSansCondensed.style.fontFamily};
      `}</style>
    }
    <main className="min-h-screen h-full w-full flex flex-col justify-between">
      <AppWithI18n {...props} />
      <Toaster />
    </main>
    <Script
      id="postaffiliate-pro-script"
      type="text/javascript"
      dangerouslySetInnerHTML={{
        __html: `
          var papScript = document.createElement('script');
          papScript.type = 'text/javascript';
          papScript.id = 'pap_x2s6df8d';
          papScript.src = 'https://filemyboir.postaffiliatepro.com/scripts/qjzn469of';
          papScript.onload = function(){papTrackClick()};
          document.body.appendChild(papScript);
          
          function papTrackClick() {
              try {
                  PostAffTracker.setAccountId('default1');
                  PostAffTracker.track();
              } catch (err) { }
          }`,
      }}
    />
  </>
)

export default AppWithAuth
