import { useCallback, useEffect, useRef, useState } from 'react'
import { faker } from '@faker-js/faker'
import Image from 'next/image'
import { Icon } from '../Icon'
import { Heading2, Heading4, Paragraph } from '../Typography'

interface CustomerCardProps {
  name: string
  image: string
  text: string
}

const CustomerCard = ({ name, image, text }: CustomerCardProps) => (
  <div className="flex flex-col gap-6 p-8 bg-white rounded-2xl min-w-[300px] min-h-[500px] md:min-w-min md:w-[405px] md:min-h-[426px]">
    <Image src={image} alt={name} width={76} height={76} className="rounded-full" />
    <div className="flex flex-col gap-2">
      <Heading4 className="text-primary text-lg font-bold font-condensed">{name}</Heading4>
      <div className="flex gap-2">
        {[...Array(5)]
          .map(() => faker.string.uuid())
          .map((id) => (
            <Icon.Star key={id} size={16} className="text-process fill-process" />
          ))}
      </div>
    </div>
    <Paragraph className="text-black text-base font-poppins">&quot;{text}&quot;</Paragraph>
  </div>
)

const ReviewsSection = () => {
  const [currentSlide, setCurrentSlide] = useState(0)
  const totalSlides = 3
  const containerRef = useRef<HTMLDivElement>(null)
  const isProgrammaticScrollRef = useRef(false)
  const cardWidth = 300 // Width of the card on mobile
  const gap = 8 // Gap between cards

  const scrollToSlide = useCallback((index: number) => {
    if (containerRef.current) {
      isProgrammaticScrollRef.current = true
      const scrollPosition = index * (cardWidth + gap)
      containerRef.current.scrollTo({
        left: scrollPosition,
        behavior: 'smooth',
      })
      // Reset the flag after the smooth scroll animation
      setTimeout(() => {
        isProgrammaticScrollRef.current = false
      }, 500)
    }
  }, [])

  useEffect(() => {
    scrollToSlide(currentSlide)
  }, [currentSlide, scrollToSlide])

  useEffect(() => {
    const container = containerRef.current
    if (!container) {
      return undefined
    }

    const handleScroll = () => {
      if (isProgrammaticScrollRef.current) {
        return
      }

      const scrollPosition = container.scrollLeft
      const newSlide = Math.round(scrollPosition / (cardWidth + gap))
      if (newSlide >= 0 && newSlide < totalSlides) {
        setCurrentSlide(newSlide)
      }
    }

    container.addEventListener('scroll', handleScroll)
    return () => container.removeEventListener('scroll', handleScroll)
  }, [])

  const handlePrevSlide = () => {
    setCurrentSlide((prev) => (prev > 0 ? prev - 1 : totalSlides - 1))
  }

  const handleNextSlide = () => {
    setCurrentSlide((prev) => (prev < totalSlides - 1 ? prev + 1 : 0))
  }

  return (
    <section className="bg-surface-50 flex flex-col gap-10 pb-[3.5rem]">
      <Heading2 className="text-center text-3xl lg:text-4xl">Customer Reviews</Heading2>
      <div className="relative">
        <div
          ref={containerRef}
          className="flex gap-2 justify-start md:justify-center overflow-y-hidden md:overflow-visible scroll-smooth px-4 lg:px-0"
        >
          <CustomerCard
            name="Jason T."
            image="/assets/images/review-1.png"
            text="I was dreading the process of filing our BOIR, but thanks to FileMyBOIR, it was a breeze. The instructions were clear and easy to follow. I was able to complete the entire process on my own without any hassle. Highly recommended for any business looking to comply with the new regulations!"
          />
          <CustomerCard
            name="Amanda W."
            image="/assets/images/review-2.png"
            text="I was impressed by the ease and efficiency of FileMyBOIR's filing service. The customer support team was knowledgeable and friendly. I highly recommend their service to any business looking for a stress-free BOIR filing experience."
          />
          <CustomerCard
            name="Dave L."
            image="/assets/images/review-3.png"
            text="FileMyBOIR made filing our BOIR incredibly straightforward. The step-by-step guidance and user-friendly interface allowed me to complete the report quickly and accurately. The service is a game-changer for small businesses like ours, ensuring we stay compliant with minimal effort."
          />
        </div>

        {/* Mobile Controls */}
        <div className="flex items-center justify-between gap-8 mt-8 md:hidden w-full px-4">
          <button
            type="button"
            onClick={handlePrevSlide}
            className="w-12 h-12 flex items-center justify-center rounded-lg border border-primary bg-white"
            aria-label="Previous review"
          >
            <Icon.ChevronLeft className="text-primary" size={24} />
          </button>

          <div className="flex gap-3">
            {[...Array(totalSlides)]
              .map(() => faker.string.uuid())
              .map((id, index) => (
                <button
                  type="button"
                  key={id}
                  onClick={() => setCurrentSlide(index)}
                  className={`w-3 h-3 rounded-full transition-colors ${
                    currentSlide === index ? 'bg-primary' : 'border-[1px] border-primary'
                  }`}
                  aria-label={`Go to slide ${index + 1}`}
                />
              ))}
          </div>

          <button
            type="button"
            onClick={handleNextSlide}
            className="w-12 h-12 flex items-center justify-center rounded-lg border border-primary bg-white"
            aria-label="Next review"
          >
            <Icon.ChevronRight className="text-primary" size={24} />
          </button>
        </div>
      </div>
    </section>
  )
}

export default ReviewsSection
